var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c("div", { staticClass: "vx-row no-gutter" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center",
                    },
                    [
                      _c("img", {
                        staticClass: "mx-auto",
                        attrs: {
                          src: require("@/assets/images/pages/register.jpg"),
                          alt: "register",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-logo flex justify-items-center items-center mb-2 pt-4",
                        },
                        [
                          _c("img", {
                            staticClass: "mx-auto",
                            staticStyle: { "max-height": "25px" },
                            attrs: {
                              src: require("@/assets/images/logo/logo.png"),
                              alt: "logo",
                            },
                          }),
                        ]
                      ),
                      _c("div", { staticClass: "p-4 ml-8 mr-8" }, [
                        _c("div", { staticClass: "vx-card__title" }, [
                          _c(
                            "h4",
                            { staticClass: "mb-3 grid justify-items-center" },
                            [_vm._v(_vm._s(_vm.$t("criar-conta")))]
                          ),
                        ]),
                        _c("div", { staticClass: "clearfix" }, [
                          _c("div", { staticClass: "pb-1 pt-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "grid mt-1 justify-items-center",
                                attrs: { id: "person_type" },
                              },
                              [
                                _c(
                                  "vs-radio",
                                  {
                                    staticClass: "hidden w-0 h-0",
                                    attrs: {
                                      "vs-name": "person_type",
                                      "vs-value": "none",
                                    },
                                    model: {
                                      value: _vm.personType,
                                      callback: function ($$v) {
                                        _vm.personType = $$v
                                      },
                                      expression: "personType",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("nenhum")))]
                                ),
                                _c(
                                  "vs-radio",
                                  {
                                    attrs: {
                                      "vs-name": "person_type",
                                      "vs-value": "student",
                                    },
                                    model: {
                                      value: _vm.personType,
                                      callback: function ($$v) {
                                        _vm.personType = $$v
                                      },
                                      expression: "personType",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Student")))]
                                ),
                                _c(
                                  "vs-radio",
                                  {
                                    attrs: {
                                      "vs-name": "person_type",
                                      "vs-value": "teacher",
                                    },
                                    model: {
                                      value: _vm.personType,
                                      callback: function ($$v) {
                                        _vm.personType = $$v
                                      },
                                      expression: "personType",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Teacher")))]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-danger text-sm col-span-2 mt-2",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.errors.first("person_type")
                                          ? "Você deve escolher um tipo de usuário"
                                          : ""
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "pb-1 pt-1" },
                            [
                              _c("label", { attrs: { for: "name" } }, [
                                _vm._v(_vm._s(_vm.$t("name"))),
                              ]),
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  "data-vv-validate-on": "blur",
                                  id: "name",
                                  name: "name",
                                },
                                model: {
                                  value: _vm.name,
                                  callback: function ($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name",
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [_vm._v(_vm._s(_vm.errors.first("name")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "pb-1 pt-1" },
                            [
                              _c("label", { attrs: { for: "email" } }, [
                                _vm._v(_vm._s(_vm.$t("email"))),
                              ]),
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|email",
                                    expression: "'required|email'",
                                  },
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  "data-vv-validate-on": "blur",
                                  id: "email",
                                  name: "email",
                                  type: "email",
                                  disabled: _vm.socialRegister,
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email",
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [_vm._v(_vm._s(_vm.errors.first("email")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "pb-1 pt-1" },
                            [
                              _c("label", { attrs: { for: "phone" } }, [
                                _vm._v(_vm._s(_vm.$t("fields.phone"))),
                              ]),
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: [
                                      "(##) ####-####",
                                      "(##) #####-####",
                                    ],
                                    expression:
                                      "['(##) ####-####', '(##) #####-####']",
                                  },
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  "data-vv-validate-on": "blur",
                                  id: "phone",
                                  name: "phone",
                                },
                                model: {
                                  value: _vm.phone,
                                  callback: function ($$v) {
                                    _vm.phone = $$v
                                  },
                                  expression: "phone",
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [_vm._v(_vm._s(_vm.errors.first("phone")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "pb-1 pt-1" },
                            [
                              _c("label", { attrs: { for: "password" } }, [
                                _vm._v(_vm._s(_vm.$t("password"))),
                              ]),
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: _vm.validateRule,
                                    expression: "validateRule",
                                  },
                                ],
                                ref: "password",
                                staticClass: "w-full",
                                attrs: {
                                  type: "password",
                                  "data-vv-as": "Senha",
                                  id: "password",
                                  name: "password",
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [_vm._v(_vm._s(_vm.errors.first("password")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "pb-1 pt-1" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "password_confirmation" } },
                                [_vm._v(_vm._s(_vm.$t("confirmacao-senha")))]
                              ),
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|confirmed:password",
                                    expression: "'required|confirmed:password'",
                                  },
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  type: "password",
                                  "data-vv-as": "Confirmação Senha",
                                  id: "password_confirmation",
                                  name: "password_confirmation",
                                },
                                model: {
                                  value: _vm.password_confirmation,
                                  callback: function ($$v) {
                                    _vm.password_confirmation = $$v
                                  },
                                  expression: "password_confirmation",
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first("password_confirmation")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "pb-1 pt-1" },
                            [
                              _c("vue-recaptcha", {
                                attrs: { sitekey: _vm.recaptchaSiteKey },
                                on: {
                                  verify: function ($event) {
                                    _vm.recaptchaVerified = true
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "flex flex-wrap justify-left my-1" },
                            [
                              _c(
                                "vs-checkbox",
                                {
                                  staticClass: "mb-3",
                                  model: {
                                    value: _vm.isTermsConditionAccepted,
                                    callback: function ($$v) {
                                      _vm.isTermsConditionAccepted = $$v
                                    },
                                    expression: "isTermsConditionAccepted",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("eu-aceito-os")))]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "float-left",
                                  attrs: {
                                    href: "https://educatena.com.br/terms-service",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("termos-e-condicoes-de-uso"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-wrap justify-center mt-4 mr-4 ml-4",
                            },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass:
                                    "w-full sm:w-auto sm:mb-auto sm:mt-auto mt-4 mr-4 ml-4",
                                  on: { click: _vm.registerUser },
                                },
                                [_vm._v(_vm._s(_vm.$t("registrar")))]
                              ),
                              _c(
                                "vs-button",
                                {
                                  staticClass:
                                    "w-full sm:w-auto sm:mb-auto sm:mt-auto mt-1  mr-4 ml-4",
                                  attrs: { type: "border", to: "/login" },
                                },
                                [_vm._v(_vm._s(_vm.$t("common.back")))]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }