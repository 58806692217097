<!-- =========================================================================================
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/register.jpg" alt="register" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="vx-logo flex justify-items-center items-center mb-2 pt-4">
                <img src="@/assets/images/logo/logo.png" alt="logo" class="mx-auto" style="max-height: 25px"/>
              </div>
              <div class="p-4 ml-8 mr-8">
                <div class="vx-card__title">
                  <h4 class="mb-3 grid justify-items-center">{{ $t('criar-conta') }}</h4>
                  <!-- <p>Informe os dados abaixo para criar uma nova conta</p> -->
                </div>

                <div class="clearfix">

                  <div class="pb-1 pt-2">
                   <!-- <label for="person_type">Tipo Usuário:</label>-->
                    <div id="person_type" class="grid mt-1 justify-items-center">
                      <vs-radio class="hidden w-0 h-0" v-model="personType" vs-name="person_type" vs-value="none">{{ $t('nenhum') }}</vs-radio>
                      <vs-radio v-model="personType" vs-name="person_type" vs-value="student">{{$t('Student')}}</vs-radio>
                      <vs-radio v-model="personType" vs-name="person_type" vs-value="teacher">{{$t('Teacher')}}</vs-radio>
                      <span class="text-danger text-sm col-span-2 mt-2">{{ errors.first('person_type')? 'Você deve escolher um tipo de usuário': '' }}</span>
                    </div>
                  </div>

                  <div class="pb-1 pt-1">
                    <label for="name">{{ $t('name') }}</label>
                    <vs-input
                      v-validate="'required'"
                      data-vv-validate-on="blur"
                      id="name"
                      name="name"
                      v-model="name"
                      class="w-full"
                    />
                    <span class="text-danger text-sm">{{ errors.first('name') }}</span>
                  </div>

                  <div class="pb-1 pt-1">
                    <label for="email">{{ $t('email') }}</label>
                    <vs-input
                      v-validate="'required|email'"
                      data-vv-validate-on="blur"
                      id="email"
                      name="email"
                      type="email"
                      v-model="email"
                      class="w-full"
                      :disabled="socialRegister"
                    />
                    <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                  </div>

                  <div class="pb-1 pt-1">
                    <label for="phone">{{ $t('fields.phone') }}</label>
                    <vs-input
                      v-validate="'required'"
                      data-vv-validate-on="blur"
                      id="phone"
                      name="phone"
                      v-model="phone"
                      class="w-full"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                    />
                    <span class="text-danger text-sm">{{ errors.first('phone') }}</span>
                  </div>

                  <div class="pb-1 pt-1">
                    <label for="password">{{ $t('password') }}</label>
                    <vs-input
                      ref="password"
                      type="password"
                      v-validate="validateRule"
                      data-vv-as="Senha"
                      id="password"
                      name="password"
                      v-model="password"
                      class="w-full"
                    />
                    <span class="text-danger text-sm">{{ errors.first('password') }}</span>
                  </div>

                  <div class="pb-1 pt-1">
                    <label for="password_confirmation">{{ $t('confirmacao-senha') }}</label>
                    <vs-input
                      type="password"
                      v-validate="'required|confirmed:password'"
                      data-vv-as="Confirmação Senha"
                      id="password_confirmation"
                      name="password_confirmation"
                      v-model="password_confirmation"
                      class="w-full"
                    />
                    <span class="text-danger text-sm">{{ errors.first('password_confirmation') }}</span>
                  </div>

                  <div class="pb-1 pt-1">
                    <vue-recaptcha
                      :sitekey="recaptchaSiteKey"
                      @verify="recaptchaVerified = true"
                    >
                    </vue-recaptcha>
                  </div>

                  <div class="flex flex-wrap justify-left my-1">
                    <vs-checkbox v-model="isTermsConditionAccepted" class="mb-3">{{ $t('eu-aceito-os') }}</vs-checkbox>
                     <a href="https://educatena.com.br/terms-service" target="_blank" class="float-left">{{ $t('termos-e-condicoes-de-uso') }}</a>
                  </div>
                  <div class="flex flex-wrap justify-center mt-4 mr-4 ml-4">
                    <vs-button
                      class="w-full sm:w-auto sm:mb-auto sm:mt-auto mt-4 mr-4 ml-4"
                      @click="registerUser">{{ $t('registrar') }}</vs-button>
                    <vs-button type="border" to="/login" class="w-full sm:w-auto sm:mb-auto sm:mt-auto mt-1  mr-4 ml-4">{{ $t('common.back') }}</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>

import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    token: {
      type: String,
      default: '',
    },
    provider: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      phone: '',
      password: '',
      password_confirmation: '',
      isTermsConditionAccepted: true,
      personType: 'none',
      socialRegister: false,
      recaptchaVerified: false
    }
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.username !== '' &&
        this.email !== '' &&
        this.phone !== '' &&
        this.password !== '' &&
        this.confirm_password !== '' &&
        (this.personType !== 'none' && this.personType !== null) &&
        this.isTermsConditionAccepted === true &&
        this.recaptchaVerified
      )
    },
    personTypeList() {
      return [
        {
          label: this.$t('aluno'),
          value: 'student'
        },
        {
          label: this.$t('professor'),
          value: 'teacher'
        }
      ]
    },
    recaptchaSiteKey() {
      return process.env.VUE_APP_RECAPTCH_SITE_KEY
    },
    validateRule() {
      return `required|verify_password_${this.$utils.browser.getDefaultLocale()}`
    }
  },
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')

      console.log('token', token)
      // Do stuff with the received token.
    },
    onEvent() {
      // when you need a reCAPTCHA challenge
      this.$refs.recaptcha.execute()
    },
    registerUser() {
      if (!this.validateForm) return false
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.notifyAlreadyLogedIn()
        return
      }

      this.$vs.loading()
      const payload = {
        userDetails: {
          name: this.name,
          email: this.email,
          phone: this.phone,
          password: this.password,
          password_confirmation: this.password_confirmation,
          person_type: (this.personType === 'none') ? null : this.personType,
          social: this.socialRegister,
          token: this.token,
          provider: this.provider
        },
        vs: this.$vs,
        closeAnimation: () => {
          this.$vs.loading.close()
        },
        showErrors: (error) => {
          this.showErrors(this.$validator, error)
        }
      }
      this.$store.dispatch('auth/registerUser', payload)
    },
    notifyAlreadyLogedIn() {
      this.$vs.notify({
        title: this.$t('login-attempt'),
        text: this.$t('you-are-already-logged-in'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
    }
  },
  created() {
    if (this.$route.name === 'social_register') {
      this.socialRegister = true
    }
  }
}
</script>
